<template>
  <div style="min-height: 700px">
    <BreadCrumbs>خانه > فاکتور های من</BreadCrumbs>
    <v-container style="max-width: 664px;padding-top: 54px">
      <h1 class="mr-5 font-weight-regular" style="font-size: 24px;">فاکتور های من</h1>

      <div class="">


        <div class="factors-content" v-for="(factor,index) in factors" :key="index">
          <div class="d-flex justify-space-between align-center mb-3">
          <p class="ma-0 primary--text" style="font-size: 20px">فاکتور شماره :
            {{factor.id}}

          </p>
          <p class="ma-0 primary--text" >{{factor.time | momentDateAndTime}}</p>
          </div>
          <p class="mb-2" style="color: #9c9c9c">
            {{factor.desc}}
          </p>
          <p class="mb-0" style="color: #7d7d7d">
            <span>
              <span class="deCash" v-if="factor.DeCash>0">
                                  {{factor.DeCash | numFormat}}-
              </span>

                <span class="addCash" v-if="factor.AddCash>0">
                                  {{factor.AddCash | numFormat}}+
              </span>
            </span> تومان
          </p>
        </div>

                <p v-if="!isfactors" class="pt-5 text-center">فاکتوری جهت نمایش وجود ندارد.</p>
      </div>

    </v-container>

  </div>
</template>

<script>
import BreadCrumbs from "../components/BreadCrumbs";
import {momentfilter} from "../plugins/moment";
import Vue from 'vue'
import axios from "axios";
import numeral from 'numeral';
import numFormat from 'vue-filter-number-format';

Vue.filter('numFormat', numFormat(numeral));
export default {
  name: 'App',
  mixins : [momentfilter],
  components : {
    BreadCrumbs
  },
  data() {
    return {
      factors : [],
      isfactors : true,
    }
  },
  metaInfo() {
    return {
      title:'فاکتور های من',
      link: [{rel: 'canonical', href: this.$store.state.url+'/factors'}]
    }
  },
  created() {
    this.fetchFactors();
  },
  methods : {
    fetchFactors(){
      axios.post(this.$store.state.api + 'factors', {
        userId : this.$store.state.userId,
      }).then(response => {
        this.factors = response.data.data;
        if(this.factors.length===0){
          this.isfactors = false;
        } else {
          this.isfactors = true;
        }
      })
    },
  }
};
</script>

<style>
.factors-content{
  background-color: #f5f5f5;
  margin: 10px auto;
  padding: 20px!important;
  border-radius: 20px;
  text-align: right;
}

.deCash{
  color: #a00;

}.addCash{
   color: #0a0;

 }
</style>
